import React from 'react';

const List =({title,link,src,des})=>
{
    return (
        <>
       
       
        <div className="  p-5 sm:p-6 sm:m-8 w-full  ">
        <a href={link} target="_blank" rel="noopener noreferrer">
        <div className="shadow-md bg-gray-50  flex-col ... 
         cursor-pointer bg-cover  rounded-lg hover:shadow-2xl   
       h-full  w-full max-w-sm lg:max-w-6xl  xl:h-48 lg:w-3/4  ">
           
             <div className="flex flex-col ... xl:flex-row ...">
                 <div className="h-48 max-w-sm   lg:max-w-sm ">
                     <img src={src}  className="w-full h-full rounded " alt="img"/>
                     </div>
                 <div className="p-3 w-full">
                     <div className=" text-xl font-semibold xl:text-2xl xl:font-black">
                         {title} 
                         </div>
                         <div className="text-sm font-medium  p-2 hidden lg:block">
                             
                  {des}

                             </div>
                 </div>
             </div>

           
        </div>
        
        </a>
        </div>
        
        
        </>
    );
}
export default List;
